/**
 * 
 *  RWD
 *  css/partials/_rwd.scss
 *
 */

@media screen and (min-width: 1200px) and (max-width: 1469px) {
    .col-lg-2-half {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        width: 20.833333%;
    }

    .col-lg-3-half {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        width: 29.166667%;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .col-md-2-half {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        width: 20.833333%;
    }

    .col-md-3-half {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        width: 29.166667%;
    }

    .text-md-right {
        text-align: right;
    }
}

@media screen and (min-width: 1470px) {
    .container {
        width: 1440px;
    }
    .col-xl-offset-0 {
        margin-left: 0;
    }
    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-xl-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11 {
        float: left;
    }

    .col-xl-1 {
        width: 8.33333%;
    }

    .col-xl-2 {
        width: 16.66667%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-4 {
        width: 33.33333%;
    }

    .col-xl-5 {
        width: 41.66667%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-7 {
        width: 58.33333%;
    }

    .col-xl-8 {
        width: 66.66667%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-10 {
        width: 83.33333%;
    }

    .col-xl-11 {
        width: 91.66667%;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-2-half {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        width: 20.833333%;
    }

    .col-xl-3-half {
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        width: 29.166667%;
    }
}

@media screen and (min-width: 1200px) {
    .text-lg-right {
        text-align: right;
    }
}

@media screen and (max-width: 1469px) {
    .global {
        &-input {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .header {
        &.small-logo .header-logo-image {
            max-width: calc(100% - 120px);
        }

        &.small-logo .header-logo.smaller .header-logo-image {
            max-width: calc(100% - 80px);
        }

        &-menu {
            li {
                margin-right: 20px;
            }
        }
    }

    .homepage {
        &-jumbotron {
            &-item {
                height: 550px;
            }
        }
    }

    .subpage {
        &-thumb {
            max-width: 146px;
            min-height: 146px;
        }
    }

    .details {
        &-jumbotron {
            &-background {
                height: 550px;
                max-height: unset;
                min-height: unset;
            }

            &-nav.top-nav {
                .owl-prev, .owl-next {
                    top: calc(50% - 95px);
                }
            }
        }
    }

    .filter {
        &-input {
            &-placeholder:before {
                padding-right: 10px;
            }

            &__metrage {
                width: 95px;

                &__shorten {
                    width: 83px;
                }
            }

            &__price {
                width: 98px;

                &__shorten {
                    width: 86px;
                }
            }

            &__rooms {
                width: 77px;
            }
        }
    }
}

@media screen and (max-width: 1199px) {
    .global {
        &-title {
            &__smallerfont {
                font-size: 60px;
                padding-top: 45px;
            }
        }
    }

    .header {
        &-logo {
            left: 15px;
            width: calc(100% - 30px);
        }

        &.small-logo .header-logo-image {
            max-width: calc(100% - 100px);
        }

        &.small-logo .header-logo.smaller .header-logo-image {
            max-width: calc(100% - 100px);
        }
    }

    .homepage {
        &-jumbotron {
            &-item {
                height: 450px;
            }

            &-nav {
                left: auto;
                right: 15px;
                transform: none;
            }
        }
    }

    .subpage {
        &-thumb {
            max-width: 172px;
            min-height: 172px;

            &:nth-child(5n + 5) {
                margin-right: 0;
            }
        }
    }

    .details {
        &-jumbotron {
            &-background {
                height: 450px;
            }
        }
    }

    .filter {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        white-space: nowrap;

        &-group {
            flex-basis: 0;
            flex-grow: 1;
        }

        &-button {
            &-search {
                margin-top: 0;
                margin-bottom: 40px;

                &.show-clear {
                    margin-right: 0;
                    max-width: 195px;
                }
            }

            &-clear {
                margin-top: 0;
                margin-bottom: 40px;
            }
        }

        &-input {
            &-placeholder {
                width: calc(100% - 28px);

                &__shorten {
                    width: calc(100% - 44px);
                }
            }

            &__metrage {
                width: 100%;
            }

            &__price {
                width: 100%;
            }

            &__rooms {
                width: 100%;
            }
        }
    }


    .offerbox {
        &-column {
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 991px) {
    .header {
        &-menu {
            li {
                a {
                    font-size: 22px;
                    padding: 0px 10px;
                }
            }
        }
    }

    .homepage {
        &-jumbotron {
            height: 450px;

            &-text {
                font-size: 68px;
                line-height: 40px;

                span {
                    &:before {
                        height: 28px;
                        left: -15px;
                        top: 23px;
                    }
                }

                small {
                    font-size: 46px;

                    &:before {
                        height: 26px;
                        left: -15px;
                        top: 10px;
                    }
                }

                &__left {
                    span {
                        &:after {
                            border-top: 14px solid transparent;
                            border-bottom: 14px solid transparent;
                            border-left: 14px solid rgba($green, 0.57);
                            top: 23px;
                            right: -40px;
                        }
                    }
                }

                &__right {
                    text-align: left;

                    span {
                        &:after {
                            border-top: 14px solid transparent;
                            border-bottom: 14px solid transparent;
                            border-right: 14px solid rgba($green, 0.57);
                            top: 23px;
                            left: -40px;
                        }
                    }
                }
            }
        }
    }

    .subpage {
        &-people {
            .row {
                &:nth-child(even) {
                    .subpage-person-firstcolumn {
                        order: 1;
                    }

                    .subpage-person-secondcolumn {
                        order: 2;
                    }

                    .subpage-person-thirdcolumn {
                        order: 3;
                    }

                    .subpage-person-content {
                        padding: 0;
                    }
                }
            }
        }

        &-person {
            &-content {
                padding: 0;
            }
        }
    }

    .subpage {
        &-thumb {
            max-width: 165px;
            min-height: 165px;

            &:nth-child(4n + 4) {
                margin-right: 0px;
            }

            &:nth-child(5n + 5) {
                margin-right: 15px;
            }
        }
    }

    .details {
        &-jumbotron {
            &-background {
                height: 450px;
            }
        }
    }

    .filter {
        &-buttonbox {
            text-align: right;
        }

        &-button {
            margin-left: 30px;
            margin-right: 0;
        }
    }

    .contact {
        &-icon {
            margin-bottom: 20px;
            margin-right: 0;
        }
    }

    .footer {
        &-menu {
            li {
                margin-right: 35px;
            }
        }

        &-copyright {
            min-height: unset;
            text-align: center;

            &__left {
                padding: 35px 0 10px;
            }

            &__right {
                padding: 10px 0 35px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .global {
        &-button {
            &__rwdmarginbottom {
                margin-bottom: 30px;
            }
        }
    }

    .header {
        &.small-logo .header-logo, &-logo {
            height: 100px;
            width: 140px;

            &.smaller {
                height: 100px;
                width: 140px;

                .header-logo-image {
                    max-width: calc(100% - 30px);
                }
            }

            &-image {
                max-width: calc(100% - 30px);
            }
        }

        &-clipboard {
            &-button {
                width: 100px;

                &text {
                    display: none;
                }
            }

            &-icon {
                margin-right: 5px;
            }

            &-counter {
                margin-left: 5px;
            }
        }

        &-langbutton {
            display: none;
        }

        &-mobile {
            &-lang {
                display: block;
            }
        }
    }

    .homepage {
        &-jumbotron {
            &-content {
                bottom: 100px;
            }

            &-nav {
                left: 50%;
                right: auto;
                transform: translateX(-50%);
            }
        }
    }

    .subpage {
        &-frame {
            margin-bottom: 30px;
        }

        &-thumb {
            max-width: 165px;
            min-height: 165px;

            &:nth-child(4n + 4) {
                margin-right: 15px;
            }
        }
    }

    .filter {
        display: flex;
        flex-wrap: wrap;

        &-buttonbox {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        &-button {
            margin: 0;
            width: calc(50% - 15px);
        }

        &-group {
            flex-basis: 0;
            flex-grow: 1;
        }

        &-input {
            &-placeholder {
                width: calc(100% - 27px);

                &__shorten {
                    width: calc(100% - 44px);
                }
            }

            &__metrage {
                width: 100%;
            }

            &__price {
                width: 100%;
            }

            &__rooms {
                width: 100%;
            }
        }
    }

    .contact {
        &-box {
            align-items: center;
        }

        &-icon {
            margin-bottom: 0;
            margin-right: 20px;
        }
    }

    .footer {
        &-menu {
            display: flex;
            justify-content: center;
            min-height: unset;
            padding: 35px 0 10px;

            li {
                margin-left: 15px;
                margin-right: 15px;
                min-height: unset;
            }
        }

        &-icons {
            justify-content: center;
            min-height: unset;
            padding: 10px 0 35px;
        }
    }
}

@media screen and (max-width: 567px) {
    .header {
        &-clipboard {
            &-details {
                width: 330px;
            }
        }
    }

    .footer {
        &-menu {
            flex-direction: column;
            padding: 35px 0 10px;

            li {
                margin-right: 0;
                min-height: unset;
                padding: 10px 0;
            }
        }
    }

    .subpage {
        &-quote {
            &:before, &:after {
                height: 100px;
                width: 130px;
            }
        }
    }
}

@media screen and (max-width: 479px) {
    .global {
        &-title {
            font-size: 28px;
        }

        &-subtitle {
            font-size: 20px;

            &__smallerfont {
                font-size: 18px;
            }
        }
    }

    .header {
        &.small-logo .header-logo, &-logo {
            height: 100px;
            width: 100px;

            &.smaller {
                height: 100px;
                width: 100px;

                .header-logo-image {
                    max-width: calc(100% - 20px);
                }
            }

            &-image {
                max-width: calc(100% - 20px);
            }
        }

        &-clipboard {
            &-button {
                width: 85px;
            }

            &-details {
                border-top-right-radius: 3px;
                left: -110px;
                right: auto;
                width: 290px;
            }
        }

        &-mobile {
            margin-left: 10px;

            &-button {
                width: 85px;
            }
        }
    }

    .homepage {
        &-box {
            &-footer {
                align-items: flex-start;
                flex-direction: column;
            }

            &-price {
                margin-bottom: 10px;
            }
        }
    }

    .subpage {
        &-quote {
            &-text {
                font-size: 20px;
            }
        }

        &-thumb {
            margin-right: 0;
            max-width: 100%;
            min-height: 200px;
        }
    }

    .details {
        &-jumbotron {
            &-background {
                display: none;
            }

            &-item {
                height: 250px;

                &:before {
                    display: none;
                }
            }

            &-bottom {
                margin-top: 20px;
            }
        }
    }

    .contact {
        &-form {
            padding: 20px;
        }
    }
}

@media screen and (max-width: 400px) {

}

@media screen and (max-width: 360px) {
    .global {
        &-button {
            &__twoicons {
                i {
                    width: 26px;
                }
            }
        }
    }
}